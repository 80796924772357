import { createContext, useCallback, useState } from 'react';
import { SiteNotification } from './types';

interface NotificationsProviderProps {
  children: React.ReactNode;
}

type NotificationsContextProps = {
  setNotifications: (notifications: MixedNotificationType[]) => void;
  notifications: SiteNotification[];
};

type MixedNotificationType = string | SiteNotification;

export const NotificationsContext = createContext<NotificationsContextProps>({} as NotificationsContextProps);

export const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
  const [notifications, setSiteNotifications] = useState<SiteNotification[]>([]);

  const setNotifications = useCallback(
    (notificationArray: MixedNotificationType[]) => {
      const formattedNotifications = [] as SiteNotification[];
      notificationArray.map((notify) => {
        if (typeof notify === 'string') {
          formattedNotifications.push({ content: notify });
        } else {
          formattedNotifications.push(notify);
        }
      });
      setSiteNotifications(formattedNotifications);
    },
    [setSiteNotifications]
  );

  const contextValue: NotificationsContextProps = {
    setNotifications,
    notifications,
  };

  return <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>;
};
