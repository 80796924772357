import { Snackbar, Alert } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SiteNotification } from './types';
import { NotificationsContext } from './NotificationsProvider';

const Notifications = () => {
  const [activeNotification, setActiveNotification] = useState<SiteNotification | null>(null);

  const { notifications } = useContext(NotificationsContext);

  const handleClose = useCallback(() => {
    if (activeNotification?.onClose) {
      activeNotification.onClose();
    }
    setActiveNotification(null);
  }, [setActiveNotification]);

  useEffect(() => {
    if (notifications.length === 0 || !!activeNotification) {
      return;
    }

    const newNotification = notifications.shift();
    if (!newNotification) {
      return;
    }

    setActiveNotification(newNotification);
  }, [notifications, activeNotification, setActiveNotification]);

  const autoHideDuration = useMemo<number | undefined>(() => {
    return activeNotification?.autoHide === false ? undefined : activeNotification?.autoHideDuration ?? 6000;
  }, [activeNotification]);

  if (!activeNotification) {
    return null;
  }

  return (
    <Snackbar
      open={!!activeNotification}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      sx={{}}
      anchorOrigin={activeNotification.position}
    >
      <Alert
        icon={activeNotification.icon}
        title={activeNotification.title}
        onClose={handleClose}
        severity={activeNotification.severity || 'info'}
        variant={activeNotification.variant || 'filled'}
        sx={{ width: '100%' }}
        action={activeNotification.action}
      >
        {activeNotification.content}
      </Alert>
    </Snackbar>
  );
};

export default Notifications;
