import { ForwardedRef, forwardRef, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { updateTotalRowsWithSubtotalCalculation } from '../tableUtils/updateTotalRowsWithSubtotalCalculation';
import PricingTableTotalFooterRow from './PricingTableTotalFooterRow';
import { RootState, useAppDispatch } from 'components/editor/grid/reduxStore/Store';
import { selectContentTable, setSelectedRow } from 'components/editor/grid/reduxStore/editorSlice';

import CellDropdownWrapper from '../CellDropdownWrapper';
import { setOfRowsForPricingTable } from '../defaultData';
import TooltipInputRedux from '../components/TooltipInputRedux';
import { setActiveTableSettingsPanel, setToggledDesignSettingModelType } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { TableSettingsTypes } from '../../../../SidePanel/content/TableSettings/types';
import { useBlockContentChangedHandler } from '../../../../hooks/UseBlockContentChangedHandler';
import { isTaxOrDiscountRow } from '../../../../../../muiTheme/dataGridUtils';
import { SidePanelProviderContext } from '../../../../SidePanel/content';
import { Box, styled } from '@mui/material';
import { COLORS, SIZES, SPACING } from '../../../../../../muiTheme/MuiDataGrid';
import { TableType, TotalRowTypesIdentifier } from '../../../../grid/reduxStore/table.types';
import getTotalFooterRowValue from './getTotalFooterRowValue';

type PricingTableTotalsFooterType = {
  isEditState?: boolean;
  sectionId: string;
  blockId: string;
  sumOfSubtotalColumn: number;
};

export const FORMATTER_KEY = 'price';
export const FooterWrapper = styled(Box)({
  overflow: 'hidden',
  borderRadius: '0 0 5px 5px',
  borderColor: 'transparent',
  width: '100%',
});

export const PricingTableTotalsFooter = forwardRef(
  ({ isEditState = false, sectionId, blockId, sumOfSubtotalColumn }: PricingTableTotalsFooterType, ref: ForwardedRef<HTMLDivElement>) => {
    const dispatch = useAppDispatch();
    const { setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);
    const { activeTableSettingsPanel } = useSelector((state: RootState) => state.blockStyleSettings);
    const isActiveFooterPanel = activeTableSettingsPanel === TableSettingsTypes.TABLE_FOOTER_ROWS;
    const blockContentChangedHandler = useBlockContentChangedHandler();
    const tableData = useSelector((state: RootState) => selectContentTable(state, sectionId, blockId)) || undefined;
    const { metadata, selectedRow, totalRows } = useSelector((state: RootState) => selectContentTable(state, sectionId, blockId));
    const totalTableRows = totalRows || setOfRowsForPricingTable;
    const selectedRowId = selectedRow?.id;
    const inputRefs = useRef({});
    const updatedRows = updateTotalRowsWithSubtotalCalculation(totalTableRows, sumOfSubtotalColumn);

    const handleRowClick = (e, row) => {
      const isInput = e.target instanceof HTMLInputElement;
      const isActiveRow = selectedRow?.id === row.id;
      if ((isInput && isActiveRow) || !isEditState) return;

      if (isEditState) {
        if (row.id !== selectedRow?.id) {
          dispatch(setSelectedRow({ sectionId, blockId, row }));
        }
        setToggledTableSettingsPanel(null);
        dispatch(setToggledDesignSettingModelType({ type: null }));
        dispatch(setActiveTableSettingsPanel({ type: TableSettingsTypes.TABLE_FOOTER_ROWS }));
      }
    };

    const onInputChange = async (value: string) => {
      const updatedRows = totalTableRows?.map((row) => (row.id === selectedRow?.id ? { ...row, price: value } : row));
      const newTableData: TableType = {
        ...tableData,
        totalRows: updatedRows,
        metadata: {
          ...metadata,
        },
      };
      await blockContentChangedHandler(blockId, sectionId, newTableData);
    };

    useEffect(() => {
      if (!isActiveFooterPanel) {
        dispatch(setSelectedRow({ sectionId, blockId, row: null }));
      }
    }, [isActiveFooterPanel]);

    useEffect(() => {
      if (selectedRowId && inputRefs.current[selectedRowId]) {
        inputRefs.current[selectedRowId].focus();
      }
    }, [selectedRow]);

    return (
      <FooterWrapper ref={ref}>
        {updatedRows?.map((row) => {
          const { isHidden, displayedValue, cellConfigObject, value } = getTotalFooterRowValue(row);
          const isActiveRow = selectedRow?.id === row.id && isTaxOrDiscountRow(row);
          const rowValue = row.rowType === TotalRowTypesIdentifier.SUBTOTAL ? sumOfSubtotalColumn : row.subtotal;
          const currencyOrPercentageSymbol = (cellConfigObject?.valueFormatter || '') as string;
          const isRowSelected = row.id === selectedRowId;

          return (
            <PricingTableTotalFooterRow
              key={row.id}
              onClick={(e) => handleRowClick(e, row)}
              title={row.title}
              rowType={row.rowType as TotalRowTypesIdentifier}
              subtotalValue={rowValue}
              priceValue={value}
              isRowSelected={isRowSelected}
              pricingDecimalPlaces={metadata?.pricingDecimalPlaces}
            >
              <Box
                data-testid={`${row.title.toLowerCase()}-row-tooltip-wrapper`}
                sx={{ backgroundColor: isHidden ? COLORS.greyedBackgroundColor : 'transparent' }}
              >
                {isActiveRow ? (
                  <TooltipInputRedux
                    inputRefs={inputRefs}
                    rowId={row.id}
                    isHidden={isHidden}
                    value={Number(value)} //todo: Rework on this
                    onInputChange={onInputChange}
                    rowType={row.rowType}
                    pricingDecimalPlaces={metadata?.pricingDecimalPlaces}
                  >
                    <CellDropdownWrapper
                      isHidden={isHidden}
                      valueFormatter={currencyOrPercentageSymbol}
                      cellConfigObject={cellConfigObject}
                      data={row}
                      blockId={blockId}
                      sectionId={sectionId}
                    />
                  </TooltipInputRedux>
                ) : (
                  <span style={{ lineHeight: SIZES.defaultRowHeight, marginRight: SPACING.sm }}>{displayedValue}</span>
                )}
              </Box>
            </PricingTableTotalFooterRow>
          );
        })}
      </FooterWrapper>
    );
  }
);

PricingTableTotalsFooter.displayName = 'PricingTableTotalsFooter';
export default PricingTableTotalsFooter;
