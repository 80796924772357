import { useSessionStorage } from 'hooks/useSessionStorage';
import { useEffect, useState } from 'react';
import { useViewerInfo } from '../identify-viewer/useViewerInfo';
import { observeVisibility } from './observeVisibility';
import { TrackingLogger } from './TrackingLogger';
import { TrackingService } from './TrackingService';

const SECTION_SELECTOR = '.preview__editor__wrapper__grid'; // tbd if page == section
const VIEW_ZONE_SELECTOR = '.preview__main';
const MAX_ATTEMPTS = 5;

const VIEWING_SESSION_KEY = 'viewingSessionId';

export const useTrackViewing = ({ documentId, accountId }: { documentId: string; accountId?: string }) => {
  const { viewer } = useViewerInfo();

  const [getViewingSessionId] = useSessionStorage(VIEWING_SESSION_KEY, crypto.randomUUID());

  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    if (!viewer.email) return;
    let attempts = 0;
    let delay = 1000;
    let timeoutId: NodeJS.Timeout | null = null;

    const verifyIfPageIsReady = () => {
      const viewZone = document.querySelector(VIEW_ZONE_SELECTOR);
      if (viewZone) {
        setIsReady(true);
        TrackingLogger.log(`useTrackViewing: ready after ${attempts} attempts to find '${VIEW_ZONE_SELECTOR}'`);
      } else {
        attempts += 1;
        if (attempts >= MAX_ATTEMPTS) {
          TrackingLogger.error(`useTrackViewing: element '${VIEW_ZONE_SELECTOR}' not found after ${MAX_ATTEMPTS} attempts`);
        } else {
          delay *= 1.5;
          timeoutId = setTimeout(verifyIfPageIsReady, delay);
        }
      }
    };

    verifyIfPageIsReady();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };
  }, [viewer]);

  useEffect(() => {
    if (!isReady || !viewer.email || !accountId) return;

    const { teardown: serviceTeardown, update } = new TrackingService({
      ...viewer,
      sessionId: getViewingSessionId() as string,
      documentId,
      accountId,
    });

    const sections = Array.from(document.querySelectorAll(SECTION_SELECTOR));
    const viewZone = document.querySelector(VIEW_ZONE_SELECTOR) as Element;
    const { teardown: observerTeardown } = observeVisibility({
      root: viewZone,
      targets: sections,
      dispatch: (sectionId, time) => update(sectionId, time),
    });

    return () => {
      observerTeardown();
      serviceTeardown();
    };
  }, [isReady, documentId, viewer, accountId]);
};
