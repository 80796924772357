import { GridProps, styled } from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';
import { Grid } from '@mui/material';
import { COLORS, SIZES, SPACING } from 'muiTheme/MuiDataGrid';
import { formatPriceWithDollarSign } from '../helper';
import { TotalRowTypesIdentifier } from '../../../../grid/reduxStore/table.types';
import { Tooltip } from '@mui/material';
import { roundedValueTooltipTitle } from '../components/CustomTableCell';
const pseudoElement = {
  content: "''",
  display: 'block',
  position: 'absolute',
  width: '100%',
  height: '1px',
};

const FooterRowWrapper = styled(Grid)(({ theme }) => ({
  fontWeight: 600,
  columnSpacing: 2,
  minHeight: SIZES.defaultRowHeight,
  justifyContent: 'center',
  alignItems: 'center',
  borderLeft: `0.1px solid ${COLORS.borderBottomColor}`,
  borderRight: `0.1px solid ${COLORS.borderBottomColor}`,
  backgroundColor: COLORS.subtotalTaxDiscountBg,
  color: COLORS.subtotalTaxDiscount,
  position: 'relative',

  '&.py-hide-total-row, &.py-hide-total-row:hover': {
    backgroundColor: COLORS.greyedBackgroundColor,
  },

  '&:last-child': {
    backgroundColor: COLORS.footerHeader,
    borderBottom: 0,
    color: theme.palette.primary.contrastText,
    borderColor: 'transparent',
  },
  '&.selected-row, &.selected-row + .unselected-row': {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.30), 0px 1px 3px rgba(0, 0, 0, 0.15)',
  },
  '&.selected-row': {
    '&::before': {
      ...pseudoElement,
      top: 0,
    },
    '&::after': {
      ...pseudoElement,
      bottom: 0,
    },
    '&:hover::before, &:hover::after': {
      backgroundColor: `${COLORS.HoverNFocus}`,
    },
    'div[data-testid="input-tax"], div[data-testid="input-discount"]': {
      '&::before': {
        ...pseudoElement,
        backgroundColor: `${COLORS.HoverNFocus}`,
        top: 0,
      },
      '&::after': {
        ...pseudoElement,
        backgroundColor: `${COLORS.HoverNFocus}`,
        bottom: 1,
      },
      borderRight: `1px solid ${COLORS.HoverNFocus}`,
      borderLeft: `1px solid ${COLORS.HoverNFocus}`,
      position: 'relative',
    },
  },
  '&::before, &::after': {
    ...pseudoElement,
  },

  '&:hover::before, &:hover::after': {
    backgroundColor: COLORS.HoverNFocus,
  },
  '&::before': {
    top: 0,
  },
  '&::after': {
    bottom: 0,
    backgroundColor: COLORS.borderBottomColor,
  },
}));

const FooterRowItem = ({
  children,
  testId,
  onClick,
  className,
  ...props
}: {
  children?: ReactNode;
  testId?: string;
  className?: string;
  onClick?: MouseEventHandler;
} & GridProps) => (
  <Grid item lineHeight="52px" md={3} textAlign="right" {...props} data-testid={testId} onClick={onClick} className={className}>
    {children}
  </Grid>
);

type PricingTableTotalFooterRowProps = {
  title: string;
  rowType: TotalRowTypesIdentifier;
  subtotalValue: number;
  onClick?: MouseEventHandler;
  isRowSelected: boolean;
  priceValue: string;
  pricingDecimalPlaces: number;
  children: ReactNode;
};

const PricingTableTotalFooterRow = ({
  title,
  rowType,
  subtotalValue,
  onClick,
  isRowSelected,
  priceValue,
  pricingDecimalPlaces,
  children,
}: PricingTableTotalFooterRowProps) => {
  const computeFormattedValue = () => {
    const hasValidSubtotalAndPrice = typeof subtotalValue === 'number' && priceValue !== '';
    const isCalculatedRow = rowType === TotalRowTypesIdentifier.SUBTOTAL || rowType === TotalRowTypesIdentifier.TOTAL;
    const shouldFormat = isCalculatedRow || hasValidSubtotalAndPrice;

    return shouldFormat ? formatPriceWithDollarSign(subtotalValue, pricingDecimalPlaces) : '';
  };

  const testIdValue = title.toLowerCase();

  return (
    <FooterRowWrapper
      container
      data-testid={`${testIdValue}-row`}
      onClick={onClick}
      className={`${isRowSelected ? 'selected-row' : 'unselected-row'} py-total-row`}
    >
      <FooterRowItem />
      <FooterRowItem testId={`title-${testIdValue}`} paddingRight={SPACING.sm}>
        <span>{title}</span>
      </FooterRowItem>
      <FooterRowItem testId={`input-${testIdValue}`}>{children}</FooterRowItem>
      <FooterRowItem testId={`value-${testIdValue}`} paddingLeft={SPACING.sm} paddingRight={SPACING.sm}>
        <Tooltip data-testid="footer-cell-tooltip" title={roundedValueTooltipTitle(subtotalValue)} placement="bottom" followCursor>
          <span>{computeFormattedValue()}</span>
        </Tooltip>
      </FooterRowItem>
    </FooterRowWrapper>
  );
};

export default PricingTableTotalFooterRow;
