import { ColumnTypeIdentifier, RowTypesIdentifier } from '../../../../grid/reduxStore/table.types';
import { shouldRoundPricingCell } from '../helper';
import OptionalRowInput from './OptionalRowInput';
import { Tooltip } from '@mui/material';

export const roundedValueTooltipTitle = (value: number): string => {
  const numValue = Number(value);
  if (value === undefined || value === null || isNaN(numValue) || numValue === 0) {
    return '';
  }

  return `Number rounded from ${numValue.toFixed(5).replace(/\.?0+$/, '')}`;
};

export const CustomTableCell = ({ params, value }: { params: any; value: string }) => {
  if (!params) {
    return <></>;
  }

  const rowType = params?.row?.rowType?.toLowerCase();
  const columnType = params?.colDef?.columnType?.toString().toLowerCase();
  const isRenderInputActive = params.colDef.columnType === ColumnTypeIdentifier.OPTIONAL && params.row.rowType === RowTypesIdentifier.BODY;
  const valueDisplay = value || '';
  const isRowOptional = params?.row?.isRowOptional ?? false;
  const isOptionalToggleAvailable = params?.row?.isOptionalToggleAvailable && isRenderInputActive;
  const hasTooltip = shouldRoundPricingCell(params?.colDef?.columnType);
  const tooltipTitle = hasTooltip ? roundedValueTooltipTitle(params.value) : '';

  return (
    <Tooltip title={tooltipTitle} placement="bottom" followCursor data-testid="render-cell-tooltip">
      <span data-testid="render-cell-test" data-testid-row-type={rowType} data-testid-column-type={columnType}>
        {isOptionalToggleAvailable ? <OptionalRowInput isRowOptional={isRowOptional} id={params.id} /> : valueDisplay}
      </span>
    </Tooltip>
  );
};

export const CustomTableCellInPreview = ({ params, value }: { params: any; value: string; children?: React.ReactNode }) => {
  const rowType = params?.row?.rowType?.toLowerCase();
  const columnType = params?.colDef?.columnType?.toString().toLowerCase();
  const valueDisplay = value || '';

  return (
    <span data-testid="render-cell-test-preview" data-testid-row-type={rowType} data-testid-column-type={columnType}>
      {valueDisplay}
    </span>
  );
};
