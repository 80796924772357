import { Box, Chip, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import IcoDoubleArrowLeft from 'components/icon/icons/IcoDoubleArrowLeft';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { usePermissionsContextValue } from 'providers/PermissionsProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { getNavigationItemsWithPermissions } from 'services/permissions/helpers/NavigationItemsPermissions';
import { CINDER_URLS } from 'utils/cinder-urls';
import { constructCinderUrl } from 'utils/constructCinderUrl';
import { FeatureFlags } from 'utils/featureFlags';
import { desktopNavigationItems } from 'utils/navigationItems';
import MobileMenu from './mobileMenu';

export interface SidebarProps {
  mode?: 'fixed' | 'collapsible';
}

export default function Sidebar({ mode }: SidebarProps) {
  const { t } = useTranslation();
  const { userPermissions } = usePermissionsContextValue();
  const itemsWithPermissions = getNavigationItemsWithPermissions(desktopNavigationItems, userPermissions);
  const defaultSelectedKey = ['' + itemsWithPermissions.findIndex((item) => item.href === location.pathname)];
  const { isDesktop, isSmallerThanDesktop } = useMediaQuery();
  const isDesktopView = mode === 'fixed' && isDesktop;
  const isMobileView = mode === 'collapsible' && isSmallerThanDesktop;
  const evaluateV3Flags = useFeatureFlag([FeatureFlags.editorV3, FeatureFlags.documentsV3]);
  const hasAnyFlag = evaluateV3Flags[FeatureFlags.editorV3] || evaluateV3Flags[FeatureFlags.documentsV3];
  const hasEditorV3Flag = evaluateV3Flags[FeatureFlags.editorV3];
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const conditionallyUpdateNavigationItem = itemsWithPermissions.map((navigationItem) => {
    if (navigationItem.id === 'documents-list') {
      return {
        ...navigationItem,
        href: '/pipeline',
      };
    }
    if (navigationItem.title === 'Content Library' && hasEditorV3Flag) {
      return {
        ...navigationItem,
        href: '#',
      };
    }
    return navigationItem;
  });

  const drawerListContent = (
    <List sx={{ display: 'flex', flexDirection: 'column', gap: '12px', padding: '0 24px 0 24px' }}>
      <ListItemText>
        <Typography fontSize="14px" lineHeight="21px" letterSpacing="0.245px" color="#41484D" fontFamily="proxima-nova" fontWeight="600">
          <a href={constructCinderUrl(CINDER_URLS.library)} data-testid="v2-library-nav-link" style={{ textDecoration: 'none' }}>
            {t('sidebar.content_library_link')}
          </a>
        </Typography>
      </ListItemText>
      <ListItemText>
        <Typography fontSize="14px" lineHeight="21px" letterSpacing="0.245px" color="#41484D" fontFamily="proxima-nova" fontWeight="600">
          <a href="/library" data-testid="library-nav-link" style={{ textDecoration: 'none' }}>
            {t('sidebar.content_library_v3_link')}
          </a>
          <Chip
            label="Beta"
            size="small"
            sx={{
              backgroundColor: '#7FD0FF',
              color: '#001E2D',
              fontFamily: 'proxima-nova',
              fontSize: '12px',
              fontWeight: '600',
              marginLeft: '4px',
            }}
          ></Chip>
        </Typography>
      </ListItemText>
      <ListItemText>
        <Typography fontSize="14px" lineHeight="16px" letterSpacing="0.18px" color="#41484D" fontFamily="proxima-nova" fontWeight="500">
          {t('sidebar.content_library_beta_text')}
        </Typography>
      </ListItemText>
    </List>
  );

  const handleContentLibraryIconClick = (navigationItem) => {
    if (navigationItem.title === 'Content Library' && evaluateV3Flags[FeatureFlags.editorV3]) {
      setIsDrawerOpen(true);
    }
  };

  if (isDesktopView) {
    return (
      <Box
        sx={{
          height: '100vh',
          backgroundColor: '#00283A',
          borderRadius: '0 1.25rem 1.25rem 0',
          padding: '1.5rem 0 3.5rem 0',
        }}
      >
        <List
          data-testid="app-sidebar"
          sx={{
            padding: 0,
            width: '100%',
          }}
        >
          <ListItem
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              padding: 0,
              gap: '0.5rem',
            }}
          >
            {(hasAnyFlag ? conditionallyUpdateNavigationItem : itemsWithPermissions).map(
              (item, index) =>
                !item.hidden && (
                  <ListItemButton
                    key={index}
                    title={item.title}
                    component={item.external ? 'a' : RouterLink}
                    href={item.external ? item.href : undefined}
                    to={item.external ? undefined : item.href}
                    selected={defaultSelectedKey.includes(index.toString())}
                    data-testid={index}
                    onClick={() => {
                      handleContentLibraryIconClick(item);
                    }}
                    sx={{
                      height: '56px',
                      alignContent: 'center',
                      '&:hover': { backgroundColor: 'rgba(84, 207, 224, 0.08)' },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        display: 'flex',
                        alignItems: 'center',

                        justifyContent: 'center',
                        minWidth: 0,
                        color: item.id === 'documents-list' ? '#54CFE0' : '#FFF',
                        '& svg': {
                          width: item.id === 'documents-list' ? 37.5 : 24,
                          height: item.id === 'documents-list' ? 37.5 : 24,
                        },
                      }}
                    >
                      <item.component />
                    </ListItemIcon>
                  </ListItemButton>
                )
            )}
          </ListItem>
        </List>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          hideBackdrop={true}
          sx={{
            '& .MuiDrawer-paper': {
              width: 252,
              background: '#f3f3f6',
              padding: 0,
              boxSizing: 'border-box',
              left: 80,
            },
          }}
        >
          <Box display="flex" justifyContent="flex-end" padding="8px">
            <IconButton onClick={() => setIsDrawerOpen(false)}>
              <IcoDoubleArrowLeft color="#41484D" />
            </IconButton>
          </Box>
          <Box padding="12px" display="flex" flexDirection="column">
            {drawerListContent}
          </Box>
        </Drawer>
      </Box>
    );
  } else if (isMobileView) {
    return <MobileMenu />;
  } else {
    return <></>;
  }
}
