import { datadogRum } from '@datadog/browser-rum';
export { DatatogTracker } from './DatadogTracker';

export const trackError = (error: Error) => {
  datadogRum.addError(error);
};

export const trackAction = (action: string, data: Record<string, unknown>) => {
  datadogRum.addAction(action, data);
};

export const setUserData = (data: Record<string, unknown>) => {
  datadogRum.setUser({ ...datadogRum.getUser(), ...data });
};
